.content-main {
  max-width: 1810px;
  margin-top: 29px;
  margin-bottom: 75px;
}

.header__list-buttonHelper {
  display: none;
  cursor: pointer;
}

.infoSchool-imgMobile {
  display: none;
}

.content-main_header__list {
  width: 1292px;
  height: 109px;
  background-color: white;
  border-radius: 54.5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(1px 1px 3px black);
  padding-left: 40px;
  padding-right: 40px;
}

.content-main_logoHeader {
  background-image: url(../../../public/img/LOGO.svg);
  width: 433px;
  height: 212px;
  border: none;
  cursor: pointer;
}
.content-main_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}

.header__list-button {
  font-size: 32px;
  font-weight: 500;
  border: none;
  background-color: white;
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid lightgray;
  }
}

.header__list-button2 {
  font-size: 32px;
  font-weight: 600;
  border: none;
  background-color: #ef1438;
  color: white;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 54.5px;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(1px 1px 3px black);
  }
}
.content-main_info1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 115px;
}

.content-main_info1__first {
  width: 1342px;
  height: 335px;
  border-radius: 54.5px;
  filter: drop-shadow(1px 1px 3px black);
  background-color: #ef1438;
  display: flex;
  align-items: center;
}

.content-main_info1__second {
  filter: drop-shadow(1px 1px 3px black);
  width: 732px;
  height: 335px;
  border-radius: 54.5px;
  background-color: white;
  margin-left: -299px;
  display: flex;
  align-items: center;
}

.first-help {
  margin-left: 47px;
  display: flex;
  gap: 47px;
}

.second-text {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-left: 50px;
}

.second-text__p {
  font-size: 24px;
  font-weight: 500;
}

.content-main_infoSchool {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.infoSchool-img {
  max-width: 1803px;
}

.content-main_infoClasses {
  max-width: 1784px;
  margin-top: 70px;
}

.infoClasses-h1 {
  color: white;
  font-weight: 700;
  font-size: 65px;
}

.infoClasses-mainInfo_block {
  width: 488px;
  height: 495px;
  background-color: white;
  border-radius: 54.5px;
}

.infoClasses-mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}

.infoClasses-mainInfo_block {
  display: flex;
}

.block-help {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 50px;
}

.mainInfo_block__h2 {
  color: #4d6bae;
  font-size: 27px;
  font-weight: 700;
}

.mainInfo_block__p {
  font-size: 24px;
  font-weight: 500;
  max-width: 340px;
}

.mainInfo_block__button {
  background-color: #ef1438;
  color: white;
  border-radius: 54.5px;
  width: 205px;
  height: 51px;
  font-weight: 700;
  font-size: 20px;
  border: none;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(1px 1px 3px black);
  }
}

.content-main_question__img {
  max-width: 1777px;
}

.content-main_question__imgMobile {
  display: none;
}

.content-main_question {
  margin-top: 100px;
}

.content-main_price__img {
  max-width: 1777px;
}

.content-main_price__imgMobile {
  display: none;
}

.content-main_price {
  margin-top: 100px;
}

.content-main_footer__connect {
  filter: drop-shadow(1px 1px 3px black);
  background-color: white;
  border-radius: 54.5px;
  display: flex;
  justify-content: space-between;
  width: 1777px;
  height: 109px;
  align-items: center;
  margin-top: 100px;
}

.footer__connect-h1 {
  font-size: 48px;
  font-weight: 700;
  color: #7390d4;
  margin-left: 50px;
}

.footer__connect-p {
  font-size: 24px;
  font-weight: 500;
}

.footer__connect-button {
  background-color: #ef1438;
  color: white;
  width: 280px;
  height: 69px;
  border-radius: 54.5px;
  border: none;
  font-size: 32px;
  font-weight: 700;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(1px 1px 3px black);
  }
}

.content-main_footer__feedback {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__feedback-h1 {
  font-size: 65px;
  color: white;
  font-weight: 700;
  margin-bottom: 43px;
}

.feedback-blockFirst_email {
  color: white;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 25px;
  text-decoration: none;
}

.feedback-blockFirst_email:hover {
  filter: drop-shadow(1px 1px 3px black);
}

.feedback-blockFirst-line {
  margin-top: 40px;
  width: 508px;
  height: 3px;
  background-color: white;
  margin-bottom: 50px;
}

.blockFirst_img-feedback {
  width: 75px;
  height: 75px;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(1px 1px 3px black);
  }
}

.feedback-blockFirst_img {
  display: flex;
  gap: 75px;
}

.hiddenImg {
  display: none;
  cursor: pointer;
}

.footer-feedback_gap {
  display: flex;
  flex-direction: column;
}

.content-main_footerLogo {
  cursor: pointer;
}

.footer-contractOffer {
  color: white;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.pointer {
  cursor: pointer;
  &:hover {
    filter: drop-shadow(1px 1px 3px black);
  }
}

.outline-none {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-contractOfferMargin {
  margin-top: 30px;
}

.footer-contractOfferMobile {
  color: white;
  display: flex;
  align-items: center;
  font-size: 18px;
  display: none;
}

@import 'ContentPageMobile';
@import 'ContentPageTablet';
@import 'ContentPageSmallMobile';
