*{
	margin: 0;
	padding: 0;
    font-family: "Montserrat", sans-serif;
}

body {
	width: 100%;
	height: 100%;
	background-color: #7390D4;
	display: flex;
	justify-content: center;
	zoom: 75%
}

body::-webkit-scrollbar {
	display: none;
  }