.personalInfo-main_offer {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 54.5px;
  filter: drop-shadow(1px 1px 3px black);
  padding: 36px;
}

.personalInfo-main_offer__h2 {
  margin-top: 50px;
  line-height: 150%;
  font-size: 32px;
  font-weight: 500;
}

.personalInfo-main_offer__h1 {
  font-size: 48px;
  font-weight: 700;
  filter: drop-shadow(1px 1px 2px black);
}

@import 'PersonalInfoMobile';
